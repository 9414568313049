<template>
    <div class="step">
        <h2>Create Step</h2>
        <form @submit.prevent="handleSubmit">
            <header>
                <span class="title" :class="{null: !data.title}">{{data.title || `title`}}</span>
                <div class="buttons">
                    <span class="preview" @click="handlePreview">Step Preview</span>
                    <button type="submit" class="btn">Save</button>
                </div>
            </header>
            <div class="items">
                <div class="left">
                    <div class="main">
                        <div class="inputs">
                            <template v-for="(input, i) in inputs" :key="i">
                                <Input :data="input" @handleData="handleData" />
                            </template>
                        </div>
                        <Documents @handleData="handleData" />
                    </div>
                    <div class="extras">
                        <Measurements @handleData="handleData" />
                        <Tools @handleData="handleData" />
                    </div>
                </div>
                <div class="right">
                    <Hotspot @handleData="handleData" />
                </div>
            </div>
        </form>
        <preview
            v-if="seePreview"
            :type="'step'"
            :info="stepInfo"
            @close="seePreview = false"
        />
    </div>
</template>

<script>
import Input from '@/components/Ui/Step/Input.vue'
import Hotspot from '@/components/Ui/Step/Hotspot/Index.vue'
import Documents from '@/components/Ui/Step/Documents.vue'
import Measurements from '@/components/Ui/Step/Measurements.vue'
import Tools from '@/components/Ui/Step/Tools.vue'
import axiosInstance from "@/services/AxiosTokenInstance";
import Preview from "@/components/previews/Preview.vue";
import { mapMutations } from 'vuex'

export default {
    components: {
        Input,
        Hotspot,
        Documents,
        Measurements,
        Tools,
        Preview
    },

    data() {
        return {
            data: {
                title: undefined
            },
            seePreview: false,
            loaded: true,
            stepInfo: {}
        }
    },

    computed: {
        inputs() {
            return [
                {
                    type: 'text',
                    name: 'title',
                    label: 'Title',
                    placeholder: 'Title',
                    required: true
                },
                {
                    type: 'textarea',
                    name: 'description',
                    label: 'Description',
                    placeholder: 'Description',
                    required: true
                }
            ]
        }
    },

    created() {
        this.getGuideDetails()
    },

    methods: {
        ...mapMutations({
            pathing: "pathing/setPathing",
        }),

        async getGuideDetails() {
            const guideId = this.$route.params.guideid;
            let response = await axiosInstance.get(`show-step/${guideId}`);
            const data = response.data.data;

            this.pathing([
                { 
                    name: "categories",
                    link: "/products/guides" },
                {
                    name: `Guides for ${data.category_details.name}`,
                    link: `/products/guides/${data.category_details.id}`,
                },
                {
                    name: data.trouble_shoot_title,
                    link: `/products/guides/${data.category_details.id}/step-list/${data.trouble_shoot_id}`,
                },
                { 
                    name: "new step"
                },
            ]);
        },

        handleData(item) {
            this.data[item.name] = item.value
            console.log(this.data)
        },

        handlePreview() {
            this.stepInfo = {
                type: "step",
                title: this.data.title || '',
                description: this.data.description || '',
                extras: {
                    image: {
                        info: [this.data.image_file] || [],
                        hotspots: this.data.hotspots || [],
                    },
                    files: this.data.documents || [],
                    measurements: this.data.measurements || [],
                },
            };
            this.seePreview = true;
        },

        async handleSubmit() {
            this.loaded = false
            
            let finalImage = [];
            if (this.data.image_file) {
                finalImage = [
                    {
                        image_file: {
                            mime: this.data.image_file.og_file.type,
                            data: this.data.image_file.link.split(',')[1],
                            size: this.data.image_file.og_file.size.toString()
                        },
                        hotspots: this.data.hotspots || [],
                    }
                ];
            }

            const finalDocs = this.data.documents?.map(el => {
                return {
                    mime: el.og_doc.type,
                    data: el.link.split(',')[1],
                    size: el.size,
                    pages: el.pages?.toString() || 0,
                }
            }) || [];

            const tools = this.data.tools?.map(el => {
                const toReturn = {
                    name: el.name
                }

                if (el.image) {
                    toReturn.icon = el.image.link
                }

                return toReturn
            }) || []

            const org = JSON.parse(localStorage.getItem("organization"));

            let bodyFormData = {
                id_organization: org.id,
                id_troubleshoot: Number(this.$route.params.guideid),
                title: this.data.title,
                description: this.data.description,
                image: finalImage,
                documents: finalDocs,
                measurements: this.data.measurements?.map(el => {
                    return {
                        name: el.name,
                        notes: el.notes ? 1 : 2,
                        scale: el.scale,
                        value: el.value,
                        variable: el.variable,
                        is_mandatory: el.is_mandatory ? 1 : 2
                    }
                }) || [],
                tools,
                status: 1
            };

            let response = await axiosInstance.post("create-troubleshoot-step", bodyFormData).finally(() => this.loaded = true);
            if (response.status == 200) {
                this.$router.push({
                path: `/products/guides/${this.$route.params.catid}/step-list/${this.$route.params.guideid}`,
                });
                this.emitter.emit('alert', 'Step created successfuly')
            }
        },
    }
}
</script>

<style lang="scss" scoped>
    .step {
        display: flex;
        flex-direction: column;

        h2 {
            font-weight: bold;
            font-size: 1.3rem;
        }

        form {
            display: flex;
            flex-direction: column;
            background: white;
            width: 100%;
            height: calc(100vh - 250px);
            border-top-right-radius: 5px;
            border-top-left-radius: 5px;
            box-shadow: 0px 1px 8px #142e6e1a;
            overflow: hidden;

            header {
                width: 100%;
                height: 4rem;
                box-shadow: 0px 1px 8px #142e6e1a;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 20px;

                .title {
                    font-size: 1.2rem;
                    font-weight: 600;

                    &.null {
                        opacity: 0.5;
                        text-transform: capitalize;
                    }
                }
                
                .buttons {
                    display: flex;
                    align-items: center;
                    gap: 1rem;

                    button {
                        font-size: 1rem;
                        font-weight: 500;
                    }
                    
                    .preview {
                        cursor: pointer;
                        user-select: none;
                        background: none;
                        text-decoration: underline;
                        transition: 0.2s;

                        &:hover {
                            color: $grey;
                        }
                    }
                }
            }

            .items {
                padding: 20px;
                display: flex;
                gap: 20px;
                overflow: auto;
                
                .left {
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                    width: 50%;

                    .main {
                        width: 100%;
                        display: flex;
                        gap: 20px;

                        .inputs {
                            width: 50%;
                            height: 15rem;
                            display: flex;
                            flex-direction: column;
                            gap: 1rem;
                        }
                    }

                    .extras {
                        width: 100%;
                        display: flex;
                        gap: 20px;
                    }
                }
                .right {
                    width: 50%;
                    height: 100%;
                }
            }
        }
    }
</style>